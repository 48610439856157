import React, { Component, useCallback, useEffect, useState } from 'react'
import './UserSignup.sass'
import { BASE } from './../basedata/constants'

import { useForm } from 'react-hook-form'
import LoadingHandler from '../Biometric/StatesComponents/LoadingHandler'

interface Props {
  onResponse: (response: any) => void
}

const maskCPF = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

// (00) 00000-0000
const maskPhone = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})(\d)/, '$1-$2')
}

// 00000-000
const maskCEP = (value: any) => {
  return value.replace(/\D/g, '').replace(/^(\d{5})(\d{3})+?$/, '$1-$2')
}

// 00/00/0000
const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

const isValidEmail = (email: any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )

function UserSignup({ onResponse }: Props) {
  const [currentStep, setCurrentStep] = useState(1)

  const [accessCode, setAccessCode] = useState('')

  const [registerError, setregisterError] = useState({
    status: false,
    message: ''
  })

  const [formData, setformData] = useState({
    fullname: '',
    externalId: ''
  })

  function VerifyUser() {
    const {
      register,
      handleSubmit,
      setError,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {

      setCurrentStep(3)

      fetch(`${BASE.api.base_url}${BASE.api.user_document_plus_validate}`, {
        method: 'POST',
        headers: {
          'x-api-key': BASE.api.api_key,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          document: data.document,
          name: data.name,
          originId: '9b31e2a0-4f38-4ff1-8977-f21ed6b5ed40'
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 'success') {

            setregisterError({
              status: false,
              message: ''
            })

            sessionStorage.setItem('token', response.token)
            sessionStorage.setItem('originCompany', '9b31e2a0-4f38-4ff1-8977-f21ed6b5ed40')

            verifyBiometry(response.token)
            // return onResponse({
            //   token: response.token,
            //   step: 'faceinformation'
            // })
          }


          setregisterError({
            status: true,
            message: 'Não encontramos um cadastro com os dados informados'
          })
          return setCurrentStep(1)
          // if (response.token) {
          //   sessionStorage.setItem('token', response.token)

          //   return onResponse({
          //     token: response.token,
          //     step: 'faceinformation'
          //   })
          // }
          // return setCurrentStep(2)
        })
        .catch((error) => {
          // if (error) {
          //   return setCurrentStep(2)
          // }
          setCurrentStep(1)
          setregisterError({
            status: true,
            message: 'Não encontramos um cadastro com os dados informados'
          })
        })

      // return onResponse('faceinformation')
    }
    if (currentStep !== 1) {
      return null
    }

    const verifyBiometry = (token: any) => {


      fetch(`${BASE.api.base_url}${BASE.api.validate_face}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: BASE.api.token
        },
        body: JSON.stringify({
          "token": token
        })
      })
        .then((resg) => resg.json())
        .then((response) => {



          if (response.biometry) {

            if (response.callbackUrl) {

              const redirect = window.sessionStorage.setItem('callbackurl', `${response.callbackUrl}`)

            }


            return onResponse({
              token: response.token,
              step: 'facesuccess'
            })


          } else {


            return onResponse({
              token: response.token,
              step: 'faceinformation'
            })
          }


        })
        .catch((error) => {
          return false
        })
    }

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <h1>
            Seja <span>bem-vindo!</span>
          </h1>
          <p>
            Por favor, informe seu Nome e NIF para prosseguir
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                placeholder="Nome"
                className="form-control form-control-lg"
                {...register('name', { required: true })}
              />
              <label className="form-label" htmlFor="name">
              Nome
              </label>
              {errors.name && (
                <span className="label-error">
                  Você precisa preencher seu Nome
                </span>
              )}
              <div className="form-floating">
                <input
                  id="document"
                  placeholder="CPF"
                  className="form-control form-control-lg"
                  {...register('document', { required: true })}
                />
                <label className="form-label" htmlFor="document">
                  CPF
                </label>
                {errors.externalId && (
                  <span className="label-error">
                    Você precisa preencher seu CPF
                  </span>
                )}
              </div>


              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input form-control-sm"
                  {...register('valid', { required: true })}
                  id="valid"
                />
                <label className="form-check-label" htmlFor="valid">
                  Eu aceito os{' '}
                  <a href="/bepass_terms.pdf" rel="noreferrer" target="_blank">
                    termos de uso e politica de privacidade
                  </a>{' '}
                  para o uso BePass
                </label>
                {errors.valid && (
                  <span className="label-error">
                    Você precisa aceitar os termos de uso
                  </span>
                )}
              </div>
            </div>
            {registerError.status && (
              <span className="label-error">{registerError.message}</span>
            )}
          </div>
          <div className="form-group form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Próximo"
              onClick={() => clearErrors()}
            />
          </div>
          {/* <a className="help-link" href="#">
          Ajuda
        </a> */}
        </form>
        <ul className="step-counter">
          <li className="active"></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  function Step2() {
    const {
      register,
      handleSubmit,
      setError,
      reset,
      formState: { errors },
      clearErrors
    } = useForm()

    const onSubmit = (data: any) => {
      const dateFormate = data.birthday.split('/')
      const validateDate = new Date(
        dateFormate[1] + '/' + dateFormate[0] + '/' + dateFormate[2]
      )
      const today = new Date()

      if (
        validateDate.getTime() > today.setHours(0, 0, 0, 0) ||
        validateDate.toString() === 'Invalid Date' ||
        data.birthday.length < 10
      ) {
        setError('birthday', {
          type: 'manual',
          message: 'Você precisa informar uma data válida'
        })
        return false
      }

      if (!isValidEmail(data.email)) {
        setError('email', {
          type: 'manual',
          message: 'Você precisa informar um E-mail válido'
        })
        return false
      }

      if (data.celphone.length < 12) {
        setError('celphone', {
          type: 'manual',
          message: 'Você precisa informar um telefone válido'
        })
        return false
      }

      let currentFormData = data

      currentFormData = {
        ...formData,
        ...currentFormData
      }

      setCurrentStep(3)

      fetch(`${BASE.api.base_url}${BASE.api.user_create}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          firstName: currentFormData.fullname
            .replace(/^\s+|\s+$/g, '')
            .split(' ')[0],
          externalId: currentFormData.externalId,
          lastName: currentFormData.fullname.replace(/^\s+|\s+$/g, ''),
          gender: '',
          document: currentFormData.document.replace(/^\s+|\s+$/g, ''),
          document_type: '',
          email: currentFormData.email.replace(/^\s+|\s+$/g, ''),
          phoneNumber: currentFormData.celphone
            .replace(/"/g, '')
            .replace(/'/g, '')
            .replace(/\(|\)/g, ''),
          birthDate:
            dateFormate[2] + '/' + dateFormate[1] + '/' + dateFormate[0],
          nationality: '',
          accessCode: `${accessCode}`,
          originCompanyId: '9b31e2a0-4f38-4ff1-8977-f21ed6b5ed40'
        })
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.onboarding_url) {
            const currentToken = response.onboarding_url.split('token=')[1]
            sessionStorage.setItem('token', currentToken)
            sessionStorage.setItem('originCompany', '9b31e2a0-4f38-4ff1-8977-f21ed6b5ed40')

            return onResponse({
              token: currentToken,
              step: 'faceinformation'
            })
          }
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    if (currentStep !== 2) {
      return null
    }

    // useEffect(() => {
    //   setTimeout(() => {
    //     reset({ name: formData.fullname })
    //   }, 3000)
    // }, [formData.fullname])

    return (
      <>
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <p>
            Certo! Agora precisamos que você informe alguns dados para
            validarmos seu cadastro.
          </p>
          <div className="form-wrapper">
            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Nome"
                {...(register('name'), { disabled: true })}
                defaultValue={formData.fullname}
              />
              <label className="form-label" htmlFor="name">
                Nome completo
              </label>
              {errors.name && (
                <span className="label-error">
                  Você precisa informar um Nome válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Data nascimento"
                {...register('birthday')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskDate(value)
                }}
              />
              <label className="form-label" htmlFor="birthday">
                Data de nascimento
              </label>
              {errors.birthday && (
                <span className="label-error">
                  Você precisa informar uma data válida
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="CPF"
                {...register('document')}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskCPF(value)
                }}
              />
              <label className="form-label" htmlFor="document">
                CPF
              </label>
              {errors.document && (
                <span className="label-error">
                  Você precisa informar um Documento válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="E-mail"
                {...register('email')}
              />
              <label className="form-label" htmlFor="email">
                E-mail
              </label>
              {errors.email && (
                <span className="label-error">
                  Você precisa informar um E-mail válido
                </span>
              )}
            </div>

            <div className="form-floating">
              <input
                className="form-control form-control-lg"
                placeholder="Celular"
                {...register('celphone', { required: true })}
                onChange={(event) => {
                  const { value } = event.target
                  event.target.value = maskPhone(value)
                }}
              />
              <label className="form-label" htmlFor="celphone">
                Celular
              </label>
              {errors.celphone && (
                <span className="label-error">
                  Você precisa informar um telefone válido
                </span>
              )}
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input form-control-sm"
                {...register('valid')}
                id="valid"
                checked
              // defaultChecked
              />
              <label className="form-check-label" htmlFor="valid">
                Eu aceito os{' '}
                <a href="/bepass_terms.pdf" rel="noreferrer" target="_blank">
                  termos de uso e politica de privacidade
                </a>{' '}
                para o uso BePass
              </label>
              {/* {errors.celphone && (
                <span className="label-error">
                  Você precisa aceitar os termos de uso
                </span>
              )} */}
            </div>
          </div>

          <div className="form-submit">
            <input
              className="btn btn-primary form-control form-control-lg"
              type="submit"
              value="Enviar"
              onClick={() => clearErrors()}
            />
          </div>
        </form>

        <ul className="step-counter">
          <li className="active"></li>
          <li className="active"></li>
          <li></li>
          <li></li>
        </ul>
      </>
    )
  }

  return (
    <>
      {/* Verify if users exists */}
      <VerifyUser />
      {/* Sign user up */}
      <Step2 />
      {/* Log user in */}
      {currentStep === 3 && <LoadingHandler />}
    </>
  )
}

export default UserSignup
